import React from 'react';
import Context from '../assets/js/Context';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Component from "@reactions/component";
import { Dialog, Pane, SideSheet, Spinner } from 'evergreen-ui';
import Cookies from "universal-cookie";
// import * as moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import MaterialDatatable from "material-datatable";
import InfoIcon from '@material-ui/icons/Info';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import host from '../assets/js/Host';
import axios from 'axios';
import { RemoveCircle } from '@material-ui/icons';
const cookies = new Cookies();
const columns = [
    { field: "name", name: "name", options: { filter: true, sort: true, } },
    { field: "info", name: "info", options: { width: 80, filter: true, sort: true, } },
    { field: "edit", name: "edit", options: { width: 80, filter: true, sort: true, } },
    { field: "delete", name: "delete", options: { width: 80, filter: true, sort: true, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
    viewColumns: false,
    search: false,
}
class Device extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subs: [],
            name: '',
            description: '',
            file: [],
            spinPage: true,
        }
    }
    // var date1 = moment(document.getElementById('date1').value).format('DD/MM/YYYY');
    componentDidMount() {
        // var officeId = localStorage.getItem('officeId')
        var header = { "Content-Type": "application/json", token: cookies.get("tokenAdmin") };

        axios.get(host + `v1/Category/`, { headers: header })
            .then(res => {
                let arr = [];
                console.log(res.data.data.category);
                var Data =res.data.data.category;
           var rev= Data.reverse()
                for (let i =2; i < rev.length; i++) {

                    let obj = {
                      
                        name: rev[i].name_en,
                        info: <Link to={`/Product/${rev[i].id}`}>
                            <InfoIcon style={{ color: '#75b4eade', cursor: 'pointer' }} />
                        </Link>,
                         edit: <Component initialState={{
                                isShown: false, name: rev[i].name_en,
                            }}>
                                {({ state, setState }) => (
                                    <React.Fragment>
                                        <SideSheet
                                            isShown={state.isShown}
                                            onCloseComplete={() => { setState({ isShown: false }) }}
                                        >
                                            <div margin={40} style={{ backgroundColor: '#fafafa', height: '100vh' }}>
                                                <div id='titlesidesheet' style={{ backgroundColor: '#2980B9', color: "#fff", textAlign: 'center' }}>Edit Category</div>
                                                <div id='inputAdd2Continer' >
                                                    <div id={'ContinerInPut'} >
                                                        <label>name </label>
                                                        <input autoComplete='off' type='text' placeholder='name' id='InputTExtDash111'
                                                           onChange={(e) => setState({ name: e.target.value })} />
                                                    </div>
                                                  
                                                </div>
                                                <div id='btnAddContiner'>
                                                    <div id='BtnAddUser' style={{ backgroundColor: '#2980B9', color: "#fff", textAlign: 'center', width: 100, float: 'center' }} onClick={() => {
                                                        this.EditSubscription(rev[i].id, state.name)
                                                        setState({ isShown: false })
                                                    }}>edit</div>
                                                </div>
    
                                            </div>
                                        </SideSheet>
                                        <EditIcon style={{ cursor: 'pointer' }} onClick={() => {
                                            setState({ isShown: true })
                                        }} />
                                    </React.Fragment>
                                )}
                            </Component>
                            ,
                        delete: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="delete game "
                                        intent="danger"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            this.deleteSubscriptions(rev[i].id)
                                        }}
                                    >
                                        <span id='msgDelete'>are sure of the deleting process</span>
                                    </Dialog>
                                    <DeleteForeverIcon style={{ color: '#d87373de', cursor: 'pointer' }}
                                        onClick={() => setState({ isShown: true })} />
                                </Pane>
                            )}
                        </Component>,
                    };
                    arr.push(obj);
                }
                this.setState({
                    items: arr, spinPage: false
                });
            })
            .catch(error => {
                console.log(error)
            })
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'center',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'center',

                },
                head: {
                    // backgroundColor: "#FF0000",
                    textTransform: 'capitalize',
                }
            },

        }
    })
    deleteSubscriptions(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("tokenAdmin") };
        axios.delete(host + `v1/Category/${id}`, { headers: header })
            .then(response => {
                this.componentDidMount();
                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error) })
    }
    EditSubscription(id, name) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("tokenAdmin") };
        let formData = new FormData();
        formData.append("name_en", name);
        axios({ url: host + `v1/Category/${id}`, method: "PUT", data: formData, headers: header })
            .then(response => {
                this.componentDidMount();
                toast('تم التعديل بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response) })

    }
    AddSubscription() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("tokenAdmin") };
        let formData = new FormData();

        formData.append("name_en", this.state.name);
        axios({ url: host + `v1/Category/`, method: "POST", data: formData, headers: header })
            .then(response => {
                this.componentDidMount();
                toast('تمت الاضافة بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response) })
    }
    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    return (
                        <div >
                            {/* <Appbar /> */}
                            <div id='btnHeaderContiner'>
                                <Component initialState={{ isShown: false }}>
                                    {({ state, setState }) => (
                                        <React.Fragment>
                                            <SideSheet
                                                isShown={state.isShown}
                                                onCloseComplete={() => setState({ isShown: false })}
                                            >
                                                <div margin={40} style={{ backgroundColor: '#fafafa', height: '100vh' }}>
                                                    <div id='titlesidesheet' style={{ backgroundColor: '#2980B9', color: "#fff", textAlign: 'center' }}>Add Category</div>
                                                    <div id='inputAdd2Continer' >
                                                        <div id={'ContinerInPut'} >
                                                            <label>name </label>
                                                            <input autoComplete='off' type='text' placeholder='name' id='InputTExtDash111'
                                                                onChange={(e) => this.setState({ name: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div id='btnAddContiner'>
                                                        <div id='BtnAddUser' style={{ backgroundColor: '#2980B9', color: "#fff", textAlign: 'center', width: 100, float: 'center' }} onClick={() => {
                                                            this.AddSubscription()
                                                            setState({ isShown: false })
                                                        }}>Add</div>
                                                    </div>

                                                </div>
                                            </SideSheet>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <div id='BtnAddUser' style={{ width: 150, backgroundColor: '#2980B9', color: "#fff", textAlign: 'center' }} onClick={() => setState({ isShown: true })}>Add Category</div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </Component>
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                            <div>
                                {!this.state.spinPage ?
                                    // <div className='DataTableContiner'>
                                    <MuiThemeProvider
                                        theme={this.getMuiTheme()}>
                                        <MaterialDatatable data={this.state.items} columns={columns} options={options} />
                                    </MuiThemeProvider>
                                    // </div>
                                    :
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh', width: '100%' }}>
                                        <Spinner />
                                    </div>
                                }
                            </div>
                        </div>
                    )

                }
                }

            </Context.Consumer>
        )
    }
}

export default Device;